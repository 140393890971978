import React from "react";
import styled from "@emotion/styled";
import { GatsbyImage } from "gatsby-plugin-image";

const MushroomListContainer = styled.div`
  padding: 20px;
  transform: translateZ(0px);
  display: none;

  @media screen and (min-width: 768px) {
    display: block;
  }

  @media screen and (min-width: 1400px) {
    padding: 80px 0;
  }
`;

const List = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: column;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

const Item = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
`;

const ProductImage = styled(GatsbyImage)`
  height: 200px;
  filter: drop-shadow(10px 10px 10px #000);

  @media screen and (min-width: 1200px) {
    height: 250px;
  }
`;

const ItemTitle = styled.p`
  font-family: "DM Sans", sans-serif;
  letter-spacing: 0.71px;
  margin: 14px 0;
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  text-transform: capitalize;
`;

const scrollInto = (id) => {
  const element = document.getElementById(id);

  if (element) {
    element.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }
};

const MushroomList = ({ products }) => (
  <MushroomListContainer>
    <List>
      {products.map((product) => (
        <Item key={product.id} onClick={() => scrollInto(product.id)}>
          <ProductImage
            image={
              product.frontmatter.thumbnail.childImageSharp.gatsbyImageData
            }
            alt={product.frontmatter.title}
          />
          <ItemTitle>{product.frontmatter.title}</ItemTitle>
        </Item>
      ))}
    </List>
  </MushroomListContainer>
);

export default MushroomList;
