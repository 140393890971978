import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout/layout";
import SEO from "../components/seo";
import MainLayout from "../components/layout/main-layout";
import { Header, HeaderImage } from "../components/layout/header";
import MushroomList from "../components/products/mushroom-list";
import MushroomsDescription from "../components/products/mushrooms-description";
import BackgroundImage from "../images/fundo-produtos.jpg";

const ProdutosPage = () => {
  const data = useStaticQuery(graphql`
    query ProdutosQuery {
      allMarkdownRemark(
        sort: { order: ASC, fields: frontmatter___order }
        filter: { frontmatter: { layout: { eq: "produto" } } }
      ) {
        nodes {
          id
          html
          frontmatter {
            title
            description
            thumbnail {
              childImageSharp {
                gatsbyImageData(quality: 100, height: 250, placeholder: BLURRED)
              }
            }
            price
            size
            visible
          }
        }
      }
    }
  `);

  const products = data.allMarkdownRemark.nodes.filter(
    (product) => product.frontmatter.visible
  );

  return (
    <Layout>
      <SEO
        title="Produtos"
        keywords={["produtos", "veggie"]}
        image={BackgroundImage}
      />

      <HeaderImage image={BackgroundImage}>
        <MainLayout size="100vh">
          <Header
            title="Os nossos produtos…"
            subtitle="Os melhores e mais saborosos Cogumelos de Argoncilhe."
          />

          <MushroomList products={products} />
        </MainLayout>
      </HeaderImage>

      <MushroomsDescription products={products} />
    </Layout>
  );
};

export default ProdutosPage;
