import React from "react";
import styled from "@emotion/styled";
import { GatsbyImage } from "gatsby-plugin-image";
import MainLayout from "../layout/main-layout";

const MushroomsDescriptionContainer = styled.div`
  background-color: var(--color3);
  padding: 20px;

  @media screen and (min-width: 768px) {
    padding: 80px;
  }

  @media screen and (min-width: 1400px) {
    padding: 80px 0;
  }
`;

const MushroomContainer = styled.div`
  display: grid;
  grid-template-columns: 100%;
  margin-bottom: 60px;

  @media screen and (min-width: 1024px) {
    grid-template-columns: 60% 40%;
  }
`;

const Title = styled.h2`
  font-family: "DM Sans", sans-serif;
  color: var(--color2);
  font-size: 40px;
  margin: 0;
`;

const Description = styled.div`
  color: var(--color2);
  font-size: 19px;
`;

const Right = styled.div`
  display: flex;
  justify-content: center;
`;

const ItemImage = styled(GatsbyImage)`
  height: 250px;
  border-radius: 10px;
`;

const MushroomsDescription = ({ products }) => (
  <MushroomsDescriptionContainer>
    <MainLayout>
      {products.map((product) => (
        <MushroomContainer id={product.id} key={product.id}>
          <div>
            <Title>{product.frontmatter.title}</Title>
            <Description dangerouslySetInnerHTML={{ __html: product.html }} />
          </div>
          <Right>
            <ItemImage
              image={
                product.frontmatter.thumbnail.childImageSharp.gatsbyImageData
              }
              alt={product.frontmatter.title}
            />
          </Right>
        </MushroomContainer>
      ))}
    </MainLayout>
  </MushroomsDescriptionContainer>
);

export default MushroomsDescription;
